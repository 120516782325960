interface Props {
    [name: string]: any
}

const Dashboard = (props: Props) => {
    return (
        <>Dashboard</>
    )
}

export default Dashboard