import { Link, NavLink } from "react-router-dom";
import styled from "styled-components";

export const SidebarContainer = styled.div`

`

export const SidebarBrand = styled(Link)`

`

export const SidebarList = styled.ul`

`

export const SidebarItem = styled.li`

`

export const SidebarNavLink = styled(NavLink)`

`

export const SidebarDropdownList = styled.ul`

`

export const SidebarDropdownItem = styled.li`

`

export const SidebarDropdownLink = styled(Link)`

`